import React from "react";
import "./style.css";
import changeThemeDark from '../../images/docs/theme-sign-theme-edit.png';
import changeThemeLight from '../../images/docs/theme-sign-theme-edit-white.png';
import accountMenuBtn from '../../images/docs/account-bar-edit.png';
import createAccBtn from '../../images/docs/sign-in-edit.png';
import registerBtn from '../../images/docs/register-edit.png';
import profileBtn from '../../images/docs/profil-edit.png';
import addressBtn from '../../images/docs/profil-save-edit.png';
import signOutBtn from '../../images/docs/theme-sign-signout-edit.png';
import aboBtn from '../../images/docs/main-menu-abo-edit.png';
import newAbo from '../../images/docs/abo-edit.png';
import confirmBuy from '../../images/docs/confirm-buy-edit.png';
import payments from '../../images/docs/main-menu-pay-edit.png';
import paymentElement from '../../images/docs/payment-history-edit.png';
import paymentElementExtra from '../../images/docs/payment-history-info-proform-edit.png';
import layoutsBtn from '../../images/docs/main-menu-Machete-edit.png';
import newLayoutBtn from '../../images/docs/machete-header-new-edit.png';
import newLayoutMenu from '../../images/docs/new-macheta-top-edit.png';
import exampleLayout from '../../images/docs/example-layout.png';
import layoutEdit from '../../images/docs/machete-list-edit.png';
import newCompBtn from '../../images/docs/new-comp-edit.png';
import compEdit from '../../images/docs/comp-list-edit.png';
import importBtn from '../../images/docs/main-menu-import-edit.png';
import step1Upload from '../../images/docs/step1-upload-edit.png';
import step1Dropdown from '../../images/docs/step1-dropdown-edit.png';
import step2Upload from '../../images/docs/step2-layout-edit.png';
import exampleError from '../../images/docs/example-error.png';
import importTop from '../../images/docs/import-top.png';
import valStatView from '../../images/docs/val-stat-view.png';

const Docs = () => {
  /**
   * Jumps to the selected article, updates URL and copies into clipboard
   * @param {String} id
   * @function
   */
  const articleTitleClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element && element.scrollIntoView({ behavior: "smooth" }); // Scroll article into view
      window.history.pushState(null, null, "#" + id); // Update URL
      navigator.clipboard.writeText(window.location.href); // Copy entire URL to clipboard
    }
  };

  return (
    <div id="documentation" className="documentation-wrapper">
      <h1>Documentație Programintrastat.ro</h1>
      <small>
        Această documentație vă va învăța să utilizați platforma
        Programintrastat.ro cât mai eficient.
      </small>
      <div className="documentation-articles">
        <div className="single-article" id="Introducere">
          <h2 onClick={() => articleTitleClick("Introducere")}>Introducere</h2>
          <p>
            Acest manual de instrucțiuni vă va ajuta să utilizați platforma
            Programintrastat.ro cât mai eficient. Imaginile de referință au fost
            capturate din program cu tema închisă la culoare. Pentru mai multe
            informații legate de tema de culoare puteți consulta capitolul
            “Temă”. Din Cuprins puteți naviga direct către capitolul care vă
            interesează cu un simplu click pe acesta. Dacă și după ce ați
            consultat acest manual nu ați putut rezolva problema ne puteți
            contacta la adresa de email programintrastat@gmail.com.
          </p>
        </div>
        {/* single-article */}

        <div className="single-article" id="Tema">
          <h2 onClick={() => articleTitleClick("Tema")}>Temă</h2>
          <p>
            Pentru a schimba tema de culoare al întregului program tot ce
            trebuie să faceți este să dați click pe următorul buton{" "}
            <span className="dot">1</span> din colțul dreapta de sus al
            ecranului.
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="change theme dark"
              src={changeThemeDark}
            />
            <img
              loading="lazy"
              alt="change theme light"
              src={changeThemeLight}
            />
          </div>
        </div>
        {/* single-article */}

        <div className="single-article" id="Cont">
          <h2 onClick={() => articleTitleClick("Cont")}>Cont</h2>
          <h3>1. Creare cont</h3>
          <p>
            Pentru a crea un cont trebui să navigați întâi la secțiunea “Cont”{" "}
            <span className="dot">1</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="account menu button"
              src={accountMenuBtn}
            />
          </div>
          <p>
            Veți ajunge în meniul “Intra în cont”. De aici puteți să vă
            autentificați cu contul dvs. odată ce îl creați. Pentru a crea un
            cont nou, din acest meniu va trebui să navigați către “Creare cont”{" "}
            <span className="dot">2</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="create account button"
              src={createAccBtn}
            />
          </div>
          <p>
            Astfel veți ajunge în meniul “Creare cont”. Odată ce veți completa
            corect formularul de pe această pagină puteți naviga în josul
            acestuia și să dați click pe butonul “Creare cont”{" "}
            <span className="dot">3</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="register button"
              src={registerBtn}
            />
          </div>
          <p>
            Dacă ați urmărit acești pași corect ar trebui să fiți redirecționați
            pe pagina principală a platformei Programintrastat.ro. Pentru a
            putea utiliza apoi platforma Programintrastat.ro va trebui să vă
            activați contul dând click pe linkul primit pe email. În cazul în
            care au apărut erori la crearea contului puteți încerca:
          </p>
          <ul>
            <li>Să urmați pașii din eroare (dacă există).</li>
            <li>Să verificați corectitudinea adresei de email.</li>
            <li>
              Să verificați corectitudinea datelor din formularul de
              înregistrare.
            </li>
            <li>Să vă asigurați că sunteți conectat la internet.</li>
            <li>
              În ultimă instanță ne puteți contacta la adresa
              programintrastat@gmail.com sau prin formularul de contact de
              pe site.
            </li>
          </ul>
          <h3>2. Editare cont</h3>
          <p>
            Pentru a vă edita contul trebuie întâi să fiți autentificat. Puteți
            face acest lucru din meniul “Cont”. Urmăriți pasul anterior pentru
            mai multe detalii. Odată autentificat, navigați către meniul
            “Profil” <span className="dot">1</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="main profile button"
              src={profileBtn}
            />
          </div>
          <p>
            Din această pagină puteți modifica atât datele personale cât și
            datele firmei. După ce ați modificat detaliile dorite apăsați
            butonul “Salvează” <span className="dot">2</span> de sub formularul
            cu datele schimbate (Date personale sau Date companie).
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="save updated profile"
              src={addressBtn}
            />
          </div>
          <h3>3. Ieșire din cont (Delogare)</h3>
          <p>
            Pentru a ieși din cont tot ce trebuie să faceți este să apăsați
            butonul roșu din colțul dreapta de sus al ecranului{" "}
            <span className="dot">1</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="log out button"
              src={signOutBtn}
            />
          </div>
        </div>
        {/* single-article */}

        <div className="single-article" id="Abonamente">
          <h2 onClick={() => articleTitleClick("Abonamente")}>Abonamente</h2>
          <p>
            Pentru a verifica orice informație legată de abonamente trebuie să
            fiți autentificat.
          </p>
          <h3>1. Verificare abonament</h3>
          <p>
            Intrați în meniul “Abonamente” <span className="dot">1</span>, iar
            acolo veți putea verifica toate informațiile legate de abonamentele
            anterioare și de cel actual.
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="main subscription button"
              src={aboBtn}
            />
          </div>
          <p>
            Puteți vizualiza date precum linii de generare rămase din lună, dar
            și perioada de valabilitate a abonamentului actual.
          </p>
          <h3>2. Creare abonament</h3>
          <p>
            Un abonament DEMO este creat automat odată cu crearea contului.
            Acesta are o valabilitate prestabilită care poate fi verificată din
            secțiunea “Abonamente”. Pentru mai multe detalii verificați
            subcapitolul anterior. Pentru crearea unui abonament nou trebuie
            întâi accesată pagina “Abonamente” <span className="dot">1</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="main subscription button"
              src={aboBtn}
            />
          </div>
          <p>
            Odată ce ați ajuns pe acea pagină trebuie să dați click pe butonul
            “Abonament nou” <span className="dot">2</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="new subscription button"
              src={newAbo}
            />
          </div>
          <p>
            Veți fi întâmpinați apoi cu o listă de abonamente împreună cu
            prețurile și informațiile acestora. Selectați pachetul dorit dând
            click pe acesta. Se va deschide o fereastră care va afișa toate
            detaliile pachetului selectat. Pentru a confirma alegerea
            abonamentului dați click pe butonul “Achiziționează”{" "}
            <span className="dot">3</span>
          </p>
          <p className="warn">
            Odată selectat abonamentul nou, cel vechi va fi dezactivat.
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Purchase subscription button"
              src={confirmBuy}
            />
          </div>
          <p>
            Așteptați apoi confirmarea achizitiei prin închiderea ferestrei
            automat. Odată ce abonamentul a fost selectat cu succes, acesta va
            fi activat odată ce plata este efectuată pentru acesta. Plata
            abonamentului se face în secțiunea “Plăți”. Pentru abonamente
            personalizate vă rugăm să ne contactați. Procesul activării unui
            abonament personalizat este diferit, iar pașii vă vor fi detaliați
            prin email sau telefonic dacă optați pentru unul. În cazul în care
            abonamentul selectat nou nu apare în lista de abonamente puteți
            încerca:
          </p>
          <ul>
            <li>Să reîncărcați pagina web.</li>
            <li>
              Să verificați dacă abonamentul anterior este valid, iar dacă este,
              să mai comandați încă odată abonamentul nou.
            </li>
            <li>Să vă asigurați că aveți o conexiune stabilă la internet.</li>
            <li>Să ne contactați.</li>
          </ul>
          <h3>3. Abonament personalizat</h3>
          <p>
            Pentru crearea unui abonament personalizat vă rugăm să ne contactați
            la adresa de email programintrastat@gmail.com
          </p>
          <h3>4. Activare abonament</h3>
          <p>
            Pentru a activa un abonament trebuie navigat în meniul “Plăți“{" "}
            <span className="dot">1</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Payments main button"
              src={payments}
            />
          </div>
          <p>
            În acest meniu trebuie observată cea mai recentă intrare din tabelul
            “Istoric plăți”. Aceasta va fi marcată ca și “Proformă”{" "}
            <span className="dot">2</span> până în momentul achitării acesteia.
            Puteți vizualiza factura aferentă abonamentului nou achiziționat
            făcând click pe butonul de sub “Factură”{" "}
            <span className="dot">3</span>. Pentru a finaliza comanda prin plata
            facturii selectați butonul “Achită”<span className="dot">4</span> de
            sub meniul “Acțiuni”.
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Payments item buttons"
              src={paymentElement}
            />
          </div>
          <p>
            Selectați modalitatea de plată dorită. În cazul în care doriți ca
            activarea abonamentul să fie prioritizată de către noi, vă rugăm să
            ne trimiteți un email la adresa programintrastat@gmail.com cu
            dovada plății atașate. Vă vom răspunde și activa abonamentul cât de
            repede posibil. Odată ce abonamentul este activat, veți putea
            vizualiza și descărca factura nou emisă din secțiunea “Plăți”.
          </p>
          <h3>5. Anulare abonament</h3>
          <p>
            Anularea unui abonament se poate efectua doar înainte de efectuarea
            plății pentru activarea acestuia. Pentru anularea abonamentului este
            nevoie să ne contactați la adresa de email
            programintrastat@gmail.com cu adresa de email cu care ați
            creat contul.
          </p>
        </div>
        {/* single-article */}

        <div className="single-article" id="FacturiSiPlati">
          <h2 onClick={() => articleTitleClick("FacturiSiPlati")}>
            Facturi și plăți
          </h2>
          <p>
            Pentru a verifica orice informație legată de facturi trebuie să fiți
            autentificat. Pentru a vizualiza întregul istoric de plăți împreună
            cu facturile aferente va trebui să intrați în meniul “Plăți”{" "}
            <span className="dot">1</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Payments main button"
              src={payments}
            />
          </div>
          <p>
            Din acest meniu puteți vizualiza informații despre fiecare factură,
            precum seria și numărul <span className="dot">2</span>, data
            emiterii <span className="dot">3</span>, startea facturii
            <span className="dot">4</span>, factura în format PDF{" "}
            <span className="dot">5</span> și meniul de acțiuni{" "}
            <span className="dot">6</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Payments item buttons extensive"
              src={paymentElementExtra}
            />
          </div>
          <p>
            Meniul de acțiuni <span className="dot">6</span> are diferite
            opțiuni depinzând de starea facturii. Atunci când factura (proformă)
            este neachitată, meniul de acțiuni <span className="dot">6</span>{" "}
            afișează butonul prin care puteți efectua plata acesteia. Odată ce
            factura este marcată ca și plătită, meniul de acțiuni{" "}
            <span className="dot">6</span>
            afișează butonul prin care puteți descărca factura în format PDF.
          </p>
        </div>
        {/* single-article */}

        <div className="single-article" id="Machete">
          <h2 onClick={() => articleTitleClick("Machete")}>Machete</h2>
          <p>
            Pentru a verifica orice informație legată de machete trebuie să fiți
            autentificat. Machetele sunt folosite de către program pentru a
            identifica din ce coloane trebuiesc luate datele din fișierul Excel
            importat. Câmpurile obligatorii sunt necesare pentru crearea unei
            declarații Intrastat valide.
          </p>
          <h3>1. Creare machete</h3>
          <p>
            Pentru a vizualiza machetele deja create și pentru a crea machete
            noi trebuie accesat meniul “Machete” <span className="dot">1</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Layouts main button"
              src={layoutsBtn}
            />
          </div>
          <p>
            Pentru a crea o machetă nouă va trebui să dați click pe butonul
            “Machetă nouă” <span className="dot">2</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="New layout button"
              src={newLayoutBtn}
            />
          </div>
          <p>Veți fi întâmpinat de meniul pentru crearea machetelor.</p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="New layout menu"
              src={newLayoutMenu}
            />
          </div>
          În acest meniu putem configura următoarele:
          <ul>
            <li>
              <span className="dot">3</span> Denumirea machetei
            </li>
            <li>
              <span className="dot">4</span> Tipul (Livrări sau Achiziții)
            </li>
            <li>
              <span className="dot">5</span> Fișierul exemplu de unde se vor lua
              denumirile coloanelor
            </li>
            <li>
              <span className="dot">6</span> Firma pentru care este creată
              macheta. Această opțiune este utilă declaranților terți.
            </li>
          </ul>
          <p>
            Odată ales fișierul Excel (.xlsx) vom putea selecta și fișa de lucru
            a fișierului Excel din care se vor luate denumirile coloanelor.
            Pentru a completa corect o machetă vor trebui completate toate
            coloanele evidențiate din formular. Exemplu completare:
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Example layout"
              src={exampleLayout}
            />
          </div>
          <p>
            După cum se poate observa în exemplu, prima valoare pe care trebuie
            să o potrivim cu un cap de coloană din fișierul Excel importat este
            Cod vamal (HS). Putem alege orice coloană din tabel, important este
            ca aceasta să fie în aceeași poziție în fișierul încărcat de fiecare
            dată când se va folosi macheta (În exemplu, coloana selectată este
            în poziția nr 5 în fișierul Excel importat).
          </p>
          <p>
            Odată ce coloana este selectată avem ocazia să introducem o valoare
            implicită pe care programul să o introducă în cazul în care aceasta
            lipsește din tabel. Aceasta poate rămâne liberă iar programul va
            evidenția lipsa valorilor obligatorii la generarea fișierului
            Intrastat.
          </p>
          <p>
            Avem opțiunea să selectăm și valoarea “Nicio coloană”. Dacă această
            opțiune este selectată, programul va crea o coloană în plus la
            generare în care va adăuga valoarea implicită setată de dvs. Cum se
            poate vedea în exemplu, valoarea “Greutate netă” are asignat “Nicio
            coloană” iar valoarea implicită este 10. Programul va crea astfel o
            coloană nouă la generare numită “Greutate netă” în care va umple
            toate rândurile cu valoarea “10”
          </p>
          <p>
            În cazul în care este selectat “Nicio coloană” iar valoarea
            implicită este necompletată programul va crea o coloană nouă la
            generare cu rânduri goale. În cazul în care coloana este o valoare
            obligatorie (evidențiată cu albastru pe fundal în crearea machetei)
            atunci programul vă va anunța lipsa valorilor obligatorii la
            generarea fișierului intrastat.
          </p>
          <p>
            Pentru a salva macheta după ce a fost completată corespunzător
            trebuie dat click pe butonul “Salvează” din josul machetei. În cazul
            în care nu se vrea salvarea machetei se poate ieși din configurarea
            machetei prin butonul “Anulează” din josul paginii sau butonul
            “Înapoi” din susul paginii.
          </p>
          <h3>1. Vizualizează, editează și șterge machete</h3>
          <p>Putem vizualiza machetele din listă în meniul “Machete”.</p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Layout menu view"
              src={layoutEdit}
            />
          </div>
          <p>
            În această listă se pot vizualiza numele machetei{" "}
            <span className="dot">1</span>, firma pentru care este folosită{" "}
            <span className="dot">2</span>, tipul <span className="dot">3</span>
            , acțiunile pe care le putem accesa pentru a edita{" "}
            <span className="dot">4</span> sau a șterge{" "}
            <span className="dot">5</span> macheta și numărul de machete rămase
            pe care le putem crea cu abonamentul actual{" "}
            <span className="dot">6</span>.
          </p>
          <p>
            Pentru a șterge o machetă trebuie doar să dăm click pe butonul “X”{" "}
            <span className="dot">5</span> roșu de la acțiunile machetei.
          </p>
          <p>
            Pentru a edita o machetă din listă trebuie dat click pe butonul
            verde <span className="dot">4</span> de la acțiunile machetei. Se va
            deschide fereastra în care vom putea edita macheta selectată. Pentru
            mai multe detalii despre completarea corectă a machetei puteți
            vizualiza capitolul anterior. Odată modificată, macheta poate fi
            salvată prin apăsarea butonului verde de la finalul formularului de
            completare.
          </p>
          <p className="warn">
            În cazul în care aveți probleme cu o machetă la importarea
            fișierului Excel (pasul 8), încercați să ștergeți macheta folosită
            și să o recreați folosind ca referință fișierul Excel pe care doriți
            să-l încărcați. Nu uitați să salvați fișierul Excel după fiecare
            modificare făcută local. Programul nu poate vedea modificările dacă
            fișierul nu este salvat înainte de a fi încărcat. Recomandăm
            închiderea fișierului înainte de a îl încărca în program, astfel
            veți fi siguri că fișierul este salvat cu ultimele modificări.
          </p>
        </div>
        {/* single-article */}

        <div className="single-article" id="Firme">
          <h2 onClick={() => articleTitleClick("Firme")}>Firme</h2>
          <p>
            Pentru a verifica orice informație legată de firme trebuie să fiți
            autentificat. Acest capitol poate fi util declaranților terți.
          </p>
          <h3>1. Creare firme</h3>
          <p>
            Firmele se pot vizualiza în meniul “Firme”. Declaranții terți vor
            putea gestiona firmele cliente în această secțiune. Datele firmelor
            sunt utilizate la generarea declarațiilor Intrastat și la asignarea
            machetelor. Pentru ceilalți utilizatori în acest meniu vor putea să
            observe doar firma lor.
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="New company button"
              src={newCompBtn}
            />
          </div>
          <p>
            Pentru a crea o firmă nouă va trebui dat click pe butonul “Firmă
            nouă”. Acesta va dezvălui un formular simplu în care trebuie
            introduse date legate de firmă:
          </p>
          <ul>
            <li>Denumire</li>
            <li>CIF</li>
            <li>Oraș (se alege din listă)</li>
          </ul>
          <p>
            Odată ce datele au fost introduse se poate salva firma apăsând
            butonul verde “Salvează” din josul formularului. Alternativ se poate
            anula acțiunea de adăugare a firmei prin apăsarea butonului
            “Anulează” din josul formularului. Valoarea “CIF” va fi apoi
            validată. Dacă valoarea nu este validă firma nu poate fi adăugată în
            listă.
          </p>
          <h3>2. Vizualizează, editează și șterge firme</h3>
          <p>Firmele pot fi vizualizate în lista din meniul “Firme”.</p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Company view"
              src={compEdit}
            />
          </div>
          <p>
            În această listă putem observa denumirea firmei{" "}
            <span className="dot">1</span>, CIF-ul firmei
            <span className="dot">2</span>, locația firmei{" "}
            <span className="dot">3</span>, acțiunile ce pot fi efectuate asupra
            firmei respective <span className="dot">4</span>,{" "}
            <span className="dot">5</span> și numărul de firme disponibil
            conform abonamentului activ <span className="dot">6</span>.
          </p>
          <p>
            Prima firmă din listă (cea cu Nr. 1) va fi mereu firma
            utilizatorului.
          </p>
          <p>
            Pentru a edita o firmă din listă trebuie dat click pe butonul verde
            <span className="dot">4</span> din lista de acțiuni a firmei
            selectate. De acolo se va afișa formularul de editare al
            informațiilor. Pentru mai multe detalii legate de acesta puteți
            vizualiza capitolul anterior.
          </p>
          <p>
            Pentru a șterge o firmă trebuie dat click pe butonul roșu “X”{" "}
            <span className="dot">5</span> din lista de acțiuni a firmei
            respective.
          </p>
        </div>
        {/* single-article */}

        <div className="single-article" id="GenerareIntarstat">
          <h2 onClick={() => articleTitleClick("GenerareIntarstat")}>
            Generare Intarstat
          </h2>
          <p>
            Pentru a verifica orice informație legată de generarea declarațiilor
            intrastat trebuie să fiți autentificat.
          </p>
          <h3>1. Importarea fișierului Excel</h3>
          <p>
            Pentru generarea fișierului Intrastat prin platforma
            Programintarstat.ro trebuie încărcat fișierului Excel cu datele
            necesare. Pentru a face asta va trebui intrat în meniul “Importă
            Excel” <span className="dot">1</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Generate Intrastat main button"
              src={importBtn}
            />
          </div>
          <p>
            Ca prim pas va trebui încărcat fișierul local făcând click pe
            butonul “+” <span className="dot">2</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Import Excel button"
              src={step1Upload}
            />
          </div>
          <p>
            Odată ce fișierul este importat, va trebui aleasă fișa de lucru
            (sheet) din meniu <span className="dot">3</span>
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Choose sheet button"
              src={step1Dropdown}
            />
          </div>
          <p>La pasul 2 se vor selecta:</p>
          <ul>
            <li>
              Macheta corespunzătoare <span className="dot">4</span>
            </li>
            <li>
              Anul corespunzător declarației <span className="dot">5</span>
            </li>
            <li>
              Luna corespunzătoare declarației <span className="dot">6</span>
            </li>
          </ul>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Import Excel choose layout"
              src={step2Upload}
            />
          </div>
          <p>
            În pasul 3 veți fi informat ce firmă este aleasă pentru declarația
            de intrastat. Dacă sunteți declaranți terți veți putea selecta
            opțiunea de declarație terță și să alegeți firma pentru care doriți
            să generați declarația intrastat.
          </p>
          <p>
            În pasul 4 (ultimul) veți vedea un rezumat al generării, pentru a vă
            asigura că toate datele introduse sunt în regulă.
          </p>
          <p className="warn">
            În cazul în care aveți probleme cu o machetă la importarea
            fișierului Excel, încercați să ștergeți macheta folosită și să o
            recreați folosind ca referință fișierul Excel pe care doriți să-l
            încărcați. Nu uitați să salvați fișierul Excel după fiecare
            modificare făcută local. Programul nu poate vedea modificările dacă
            fișierul nu este salvat înainte de a fi încărcat. Recomandăm
            închiderea fișierului înainte de a îl încărca în program, astfel
            veți fi siguri că fișierul este salvat cu ultimele modificări.
          </p>
          <h3>2. Prelucrarea, generarea și verificarea fișierului Excel</h3>
          <p>
            Fișierul Excel a fost importat în program. La pasul 4 din meniul de
            importare, după ce ați verificat ca datele să fie valide, putem
            apăsa butonul “ÎNCARCĂ”. Fișierul va fi încărcat, acest proces poate
            dura de la cateva secunde la peste un minut, depinzând de
            dimensiunea fișierului.
          </p>
          <p>
            Fiți atenți la notificările generate de program din colțul de
            dreapta sus. Odată încărcat, veți fi întâmpinați de meniul de
            prelucrare și generare al fișierului. De aici vom putea vedea
            perioada pentru care declarația intrastat va fi generată, tipul
            declarației (“Livrări” sau “Achiziții”) și firma pentru care este
            realizat. În cazul firmelor terțe, aici vor apărea ambele firme.
          </p>
          <p>
            În cazul în care există erori în fișier, acestea vor fi evidențiate
            cu roșu. Datele din fișier pot fi modificate direct în program,
            astfel că erorile pot fi rezolvate direct.
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Example import error"
              src={exampleError}
            />
          </div>
          <p>
            După cum se poate observa în această imagine, eroare este la “Cod
            Vamal”. Căsuța este evidențiată cu roșu, iar “Nr.” este și el
            evidențiat. Dând click pe “Nr.” ne va afișa toate coloanele care au
            erori de pe acest rând. Coloanele cu erori se pot modifica direct în
            tabel, iar programul va verifica modificare în timp real. Dacă
            valoare introdusă va fi validă, evidențierea cu roșu va fi
            dezactivată.
          </p>
          <p className="warn">
            Nu toate coloanele vor fi verificate automat după ce modificați
            valoarea. Coloane precum Valoare VAT vor trebui validate manual.
            Dacă aveți dubii în privința unor valori considerate “corecte”
            verificați de câte ori este nevoie. Programul poate evidenția valori
            greșite și dacă macheta este greșită. De ex. dacă programul crede că
            în coloana X se va afla codul vamal, iar de fapt în acea coloană
            este valoarea fiscală, atunci va evidenția toata coloana cu roșu.
            Este important să verificați validitatea machetei, dacă ordinea
            coloanelor din fișierul excel se schimbă, recreați macheta de la 0
            pentru a evita orice eroare de genul acesta. În cazul problemelor cu
            macheta la importarea fișierului, ștergeți și recreați macheta de la
            0.
          </p>
          <p>
            Meniul din partea de sus a paginii de generare constă în mai multe
            butoane cu diferite funcționalități. Pentru a afla mai multe despre
            fiecare, țineți mouse-ul deasupra butonului respectiv, iar un meniu
            informativ va apărea.
          </p>
          <p>
            Înainte să intrăm în detalii legate de tabelul de editare al
            fișierului importat și funcționalitățile acestuia, vom afla detalii
            legate de butoanele din partea de sus a tabelului:
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Import top view"
              src={importTop}
            />
          </div>
          <p>
            După cum se poate vedea în imaginea de mai sus, avem următoarele
            butoane:
          </p>
          <h4>Calcul valoare statistică:</h4>
          <p>
            Această funcție poate fi activată pentru a calcula valoare
            statistică automat. Odată ce este activată funcția, veți putea apăsa
            butonul albastru de jos cu textul “Calcul val stat” și veți fi
            întâmpinat de această fereastră:
          </p>
          <div className="article-images">
            <img
              loading="lazy"
              alt="Val stat window view"
              src={valStatView}
            />
          </div>
          <p>
            Programul va identifica toate valorile identice ca și câmpuri din
            acest tabel și le va grupa. Semnul va apărea ca “MINUS” sau “PLUS”,
            acesta este setat automat de către program în funcție de criteriile
            don tabel, însă poate fi modificat cu ușurință dând click pe acesta.
            Procentul trebuie pus manual, acesta este 0 ca valoare inițială.
            Odată ce ați completat procentul și ați verificat ca semnul să fie
            valid, puteți face click pe “Calculează”, iar programul va face
            toată treaba. În câteva secunde veți avea rezultatul într-o coloană
            nouă.
          </p>
          <h4>Declarație revizuită:</h4>
          <p>
            Dacă selectați această opțiune, declarația Intrastat generată va fi
            marcată ca și revizuită.
          </p>
          <h4>XML detaliat:</h4>
          <p>
            Această opțiune este strict pentru cei interesați de generarea unui
            fișier XML în care articolele nu vor fi grupate, așa cum sunt în mod
            normal la generarea unui fișier XML pentru declarația INTRASTAT.
          </p>
          <h4>Afișează doar erori:</h4>
          <p>
            Această funcție este foarte utilă, deoarece vă permite să
            vizualizați exact unde se află erorile din fișierul importat și
            astfel să puteți corecta mai ușor. Vă recomandăm să bifați această
            opțiune înainte de generarea finală a fișierului, deoarece puteți
            identifica dacă există greșeli pe care le-ați omis.
          </p>
          <h4>Afișează doar necesare:</h4>
          <p>
            Această funcție poate fi utilă dacă aveți multe coloane în tabelul
            importat. Dacă este activată, veți putea să lucrați doare pe
            coloanele necesare pentru declarația Intrastat, iar celelalte
            coloane vor fi ascunse. La fel ca orice altă funcție, o puteți
            dezactiva oricând și coloanele ascunse vor reapărea.
          </p>
          <h4>Lățime întreagă tabel:</h4>
          <p>
            Dacă selectați această funcție coloanele din tabel vor avea o lățime
            mai mare pentru a permite o vizibilitate mai bună a datelor. Această
            funcție este recomandată în combinație cu “Afișează doar necesare”.
          </p>
          <h4>Generare:</h4>
          <p>
            Acest buton va porni generarea fișierului XML pentru declarația
            Intrastat. Dacă îl veți apăsa nu veți folosi din liniile disponibile
            din abonament. Întâi vă va apărea o fereastră în care va trebui să
            confirmați detaliile legate de declarația Intrastat (Persoană de
            contact, poziție, etc.) și veți fi informat cu privire la câte linii
            din abonament veți folosi și câte veți mai avea disponibile.
            Important: Odată ce apăsați butonul, așteptați ca încărcarea să se
            finalizeze înainte de a apăsa altceva.
          </p>
          <h4>Verificare VAT:</h4>
          <p>
            Acest buton va începe procesul de verificare al codurilor VAT din
            tabel (dacă sunt prezente). Procesul poate dura câteva minute.
          </p>
          <p className="warn">
            Odată ce apăsați butonul, așteptați ca încărcarea să se finalizeze
            înainte de a apăsa altceva.
          </p>
          <h4>Calcul val stat:</h4>
          <p>
            Acest buton va fi activ doar în cazul în care ați bifat “Calcul
            valoare statistică” și vă va permite calculul automat a valorii
            statistice.
          </p>
          <h4>Exportă excel:</h4>
          <p>
            Această funcție vă permite descărcarea în format Excel a tabelului
            afișat. Este util în cazul în care ați făcut modificări sau ați
            corectat erori în tabel, astfel puteți avea o copie locală corectă
            pe care o veți putea importa de fiecare dată, fără a mai avea
            probleme.
          </p>
          <h4>Exportă erori:</h4>
          <p>
            Această funcție va exporta doar erorile din tabel în format excel.
          </p>
          <br />
          <p>În tabel veți avea următoarele funcționalități:</p>
          <ul>
            <li>Număr pentru fiecare rând.</li>
            <li>
              Validare a codului vamal cu posibilitatea de a căuta codul necesar
              într-o listă care cuprinde toate codurile disponibile. Veți putea
              filtra acea listă prin denumire și cod.
            </li>
            <li>
              Validare a condiției de livrare cu posibilitatea de a căuta codul
              necesar într-o listă care cuprinde toate codurile disponibile.
              Veți putea filtra acea listă prin denumire și cod.
            </li>
            <li>
              Validare a modului de transport cu posibilitatea de a căuta codul
              necesar într-o listă care cuprinde toate codurile disponibile.
              Veți putea filtra acea listă prin denumire și cod.
            </li>
            <li>
              Validare a țării de origine cu posibilitatea de a căuta codul
              necesar într-o listă care cuprinde toate codurile disponibile.
              Veți putea filtra acea listă prin denumire și cod.
            </li>
            <li>
              Validare a țării de expediere cu posibilitatea de a căuta codul
              necesar într-o listă care cuprinde toate codurile disponibile.
              Veți putea filtra acea listă prin denumire și cod.
            </li>
            <li>
              Validare a naturii tranzacției cu posibilitatea de a căuta codul
              necesar într-o listă care cuprinde toate codurile disponibile.
              Veți putea filtra acea listă prin denumire și cod.
            </li>
            <li>
              Cod UMS și valoare UMS generate automat în funcție de condiția de
              livrare și a cantității.
              <p className="warn">
                Pentru a putea genera automat valoare UMS este nevoie să treceți
                în machetă locația cantității în tabel. Fără aceasta, valoarea
                UMS nu poate fi generată automat.
              </p>
            </li>
            <li>
              Verificare în timp real a modificărilor din tabel. Toate datele
              sunt verificate pe cât posibil în cel mai scurt timp. Date precum
              validarea valorilor VAT trebuiesc validate separat, dând click pe
              butonul necesar.
            </li>
            <li>
              Posibilitatea de a edita manual fiecare celulă din tabel, și apoi
              de a salva tabelul în format Excel pentru a îl folosi ulterior.
            </li>
          </ul>
          <p>
            Cu aceste informații puteți genera cu ușurință o declarație
            Intrastat validă. În cazul în care aveți întrebări sau nelămuriri
            verificați secțiunea “Întrebări frecvente”. Dacă nu găsiți ceea ce
            căutați, ne puteți contacta prin formularul de contact de pe
            platfomă sau direct la adresa programintrastat@gmail.com
          </p>
          <p className="warn">
            Valorile VAT vor trebui să fie în format “BG123456” pentru fi
            verificate. Formatul “BG 123456” sau “BG_123456” va fi invalid.
          </p>
        </div>
        {/* single-article */}

        <div className="single-article" id="FAQ">
          <h2 onClick={() => articleTitleClick("FAQ")}>Întrebări frecvente</h2>
          <p>
            În această secțiune puteți verifica dacă exista răspunsuri în cazul
            în care aveți nelămuriri.
          </p>
          <ol>
            <li>
              Ce format de fișier Excel acceptă programul? Răspuns: Programul
              acceptă format .xlsx și .xls
            </li>
            <li>
              Am achiziționat din greșeală un abonament nou iar cel vechi a fost
              dezactivat. Îl pot recupera? Răspuns: Vă puteți recupera
              abonamentul vechi dacă cel nou este dezactivat iar cel anterior
              este încă valid. Pentru dezactivarea unui abonament trebuie să ne
              contactați la adresa de email programintrastat@gmail.com sau
              prin formularul de contact. Abonamentul nu poate fi anulat dacă a
              fost achitat.
            </li>
            <li>
              Am achitat un abonament nou, însă nu pot folosi încă programul?
              Răspuns: În cazul în care ați efectuat plata unui abonament poate
              dura puțin până aceasta este procesată iar abonamentul va fi
              activ. Dacă totuși considerați că a trecut mai mult timp de la
              achitarea abonamentului, iar acesta nu este încă activ
              contactați-ne la adresa de email programintrastat@gmail.com
            </li>
            <li>
              Dacă încep un abonament demo și apoi doresc să trec la un
              abonament plătit se vor păstra toate datele mele? Răspuns: Toate
              datele ce țin de contul dvs vor fi disponibile indiferent de
              abonamentul ales, indiferent că este demo sau cu plată.
            </li>
            <li>
              Nici unul din abonamentele disponibile nu îmi satisface nevoile.
              Pot avea un abonament la comandă? Răspuns: Abonamentele la comandă
              se pot face contactându-ne la adresa de email
              programintrastat@gmail.com
            </li>
            <li>
              Aș dori să modific date precum CUI-ul firmei mele în cont. Pot
              face acest lucru? Răspuns: Date precum CUI-ul și numărul la Reg
              Com al firmei nu pot fi modificate de către dvs. Pentru astfel de
              modificări vă rugăm să ne contactați.
            </li>
            <li>
              Vreau să-mi fac un cont, însă nu am voie să folosesc firma aleasă.
              De ce? Răspuns: Este posibil ca un cont să existe deja cu acea
              firmă. În cazul acesta ne puteți contacta și vom putea remedia
              situația. În principiu nu este permis mai mult de un cont/firmă.
            </li>
            <li>
              Vreau sa generez o declarație Intrastat însă nu doresc ca datele
              legate de furnizori/clienți să ajungă publice/unde nu trebuie. Pot
              avea încredere în program? Răspuns: Datele introduse din fișierele
              Excel pentru generarea declarației Intrastat nu sunt prelucrate pe
              server. Acestea sunt prelucrate local, ceea ce înseamnă că nimeni
              în afară de dvs nu are acces la datele acelea. Datele din fișierul
              excel și din declarația Intrastat generată nu sunt folosite nici
              măcar pentru statistici. Tot ce înseamnă date încărcate de către
              dvs și generate de către program sunt prelucrate pe calculatorul
              dvs.
            </li>
            <li>
              Am erori atunci când încarc fișierul XML generat în aplicația
              oficială INTRASTAT. Ce pot face? Răspuns:
              <ul>
                <li>
                  Ștergeți macheta pe care ați folosit-o la generare și să o
                  refaceți de nouă cu fișierul Excel folosit ca referință.
                </li>
                <li>
                  Verificați că ați ales fișa de lucru corectă din fișierul
                  Excel la crearea machetei, dar și la prelucrarea fișierului
                  Excel.
                </li>
                <li>
                  Verificați dacă aveți cele mai noi nomenclatoare importate și
                  activate în aplicația INTRASTAT oficială.
                </li>
                <li>
                  Încercați să eliminați coloane/rânduri goale din fișierul dvs
                  Excel (dacă eliminați coloane va trebui să refaceți macheta).
                </li>
                <li>
                  Verificați dacă ați salvat ultimele modificări în fișierul
                  Excel înainte de a-l importa.
                </li>
                <li>
                  În cazul în care aveți valori VAT în tabel, acestea va trebui
                  verificate dând click pe butonul “Verificare VAT” înainte de
                  generarea fișierului XML.
                </li>
                <li>
                  În cazul în care nicio metodă nu funcționează ne puteți
                  contacta.
                </li>
              </ul>
            </li>
            <li>
              Am generat fișierul XML. Ce fac cu acesta? Răspuns: Fișierul
              rezultat din generarea programului va trebui încărcat în programul
              oficial INTRASTAT pe care îl puteți găsi online. Acesta va valida
              fișierul.
            </li>
          </ol>
        </div>
        {/* single-article */}
      </div>
      {/* documentation-articles */}
    </div>
  );
};

export default Docs;
